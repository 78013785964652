<script setup>
import {useBaseStore} from "~/stores/base";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="not-found-content-mobile" v-if="isMobile">
    <img src="@/assets/img/icon-not-found.png" alt="not-found">
    <div class="tips">
      Oops, there's nothing here.
      <br>
      You can return to the homepage or check if the URL is correct.
      <br>
      Thanks for your patience!
    </div>
  </div>
  <div class="not-found-content-desktop" v-else>
    <img src="@/assets/img/icon-not-found.png" alt="not-found">
    <div class="tips">
      Oops, there's nothing here.
      <br>
      You can return to the homepage or check if the URL is correct. Thanks for your patience!
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.not-found-content-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  height: calc(100vh - $mobile-header-height);

  img {
    width: 121.44px;
    margin: 0 auto;
    object-fit: contain;
  }

  .tips {
    text-align: center;
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
  }
}

.not-found-content-desktop {
  display: flex;
  flex-direction: column;
  gap: 23px;
  padding: 200px 0;

  img {
    width: 220.737px;
    height: 155.748px;
    margin: 0 auto;
    object-fit: contain;
  }

  .tips {
    text-align: center;
    font-family: TWK Lausanne;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
</style>
